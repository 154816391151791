export default {
  'forgot.password.title': 'Quên mật khẩu',
  'forgot.password.description':
    'Vui lòng nhập địa chỉ email đã đăng ký của bạn để nhận thông tin khôi phục mật khẩu',
  'forgot.password.notification':
    'Liên kết khôi phục mật khẩu đã được gửi đến email của bạn. Vui lòng kiểm tra email và nhấp vào liên kết đính kèm trong thư để bắt đầu đặt lại mật khẩu.',
  'forgot.password.email': 'Email',
  'forgot.password.back': 'Quay lại đăng nhập',
  'forgot.password.button.accept': 'Xác nhận',
  'forgot.password.email.not.exit': 'Email không tồn tại',
  'forgot.password.email.invalid': 'Email không hợp lệ!',

  'forgot.password.code.title': 'Xác minh',
  'forgot.recoveryCode': 'Nhập mã xác nhận',
  'forgot.desc': 'Vui lòng nhập địa chỉ email đã đăng ký để yêu cầu khôi phục mật khẩu',
  'forgot.text.desc':
    'Liên kết khôi phục mật khẩu đã được gửi đến email của bạn. Vui lòng kiểm tra email và nhấp vào liên kết trong thư để bắt đầu đặt lại mật khẩu.',
  'forgot.password.username': 'Tên người dùng',
  'forgot.required.username': 'Vui lòng nhập tên người dùng',
};
