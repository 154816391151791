import React from 'react';

import { UilSetting } from '@iconscout/react-unicons';
import { EPermissions } from '@modules/permissions/permissionsEntity';
import { IRouter } from '@routers/interface';

export const routerUser: IRouter = {
  path: '/settings/user',
  loader: React.lazy(() => import('./Pages/User/index')),
  exact: true,
  name: 'user.management.breadcrumb', //translate here for breadcrumb and sidebar
  masterLayout: true,
  permissionCode: EPermissions.UsersView,
};

export const routerRoles: IRouter = {
  path: '/settings/roles',
  loader: React.lazy(() => import('./Pages/Roles/index')),
  exact: true,
  name: 'role.management.breadcrumb', //translate here for breadcrumb and sidebar
  masterLayout: true,
  permissionCode: EPermissions.RolesView,
};

export const routerSetting: IRouter = {
  path: null,
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'setting.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
  menu: {
    icon: <UilSetting />,
    activePath: '/settings',
  },
  permissionCode: [EPermissions.UsersView, EPermissions.RolesView],
  routes: [routerUser, routerRoles],
};
