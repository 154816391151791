export default {
  'users.name': 'First and last name',
  'user.management': 'User',
  'users.userName': 'User name',
  'user.management.breadcrumb': 'User management',
  'users.fullName': 'First and last name',
  'users.phoneNumber': 'Phone number',
  'users.action': 'Action',
  'users.update': 'Update user',
  'users.information': 'User information',
  'users.password': 'Password',
  'users.userType': 'Role',
  'users.create': 'Create user',
  'users.delete': 'Delete user',
  'user.content.delete': 'Are you sure you want to delete this user?',
  'users.citizenId': 'ID/CCCD number',
  'users.email': 'Email',

  'users.table.username': 'user name',
  'users.table.name': 'First and last name',
  'users.table.type': 'Account type',
  'users.table.role': 'Role',
  'users.table.phoneNumber': 'Phone number',
  'users.table.email': 'email',
  'users.table.action': 'Act',

  /////// User
  'users.roles': 'Roles',
  'users.birthday': 'Date of Birth',
  'user.name': 'User',
  'user.list': 'User list',
  'users.displayName': 'Time zone',
  'user.username': 'User name',
  'user.userFullName': 'First and last name',
  'user.email': 'email',
  'user.userPhone': 'Phone number',
  'user.userAvatar': 'Avatar',
  'user.userPassword': 'Password',
  'users.modal.form.confirmPassWord': 'Confirm password',
  'users.modal.form.roleId': 'Role',
  'user.isActive': 'Operating status',
  'users.modal.form.password.placeholder': 'Please enter a password',
  'users.modal.form.confirmPassWord.placeholder': 'Please enter the password confirmation',
  'users.modal.form.confirmPassWord.required':
    'The two passwords you have entered without matching',
  'user.validator-userName': 'The username is not allowed to leave the blank',
  'user.validator-userFullName': 'Please enter the first and last name',

  'users.validate-password': 'The password is incorrectly formatted',
  'users.validate-phone': 'Phone number improperly formatted',

  'users.status': 'Status',
  'users.status.param': '{status,select, 1 {Active} 2 {Inactive} other {Unknown}}',

  'settings.name': 'System installation',
  'users.title': 'List of user decentralized',
  'users.tab.name': 'User',
  'user.filter.status': 'Status',
  'user.status.active': 'Active',
  'user.status.notActive': 'Inactive',
  'roles.placeholder.name': 'Please choose the role',
  'userList.role.name': 'Role',
  'users.havePermission': 'Decentralized',
  'users.noPermission': 'Unauthorized',
  'users.noPermission.title': 'List of users have not decentralized',
  'users.delete.title': 'Confirm account deletion?',
  'users.delete.content': 'This account information data will be deleted.',
  'users.identifierNumber': 'ID/CCCD number',

  'users.validator-userName': 'Please enter the username',
  'users.validatorUserName': 'Username in the wrong format',
  'users.title.endUser': 'List of system users',
  //facilitiesUsers
  'facilitiesUsers.role.name': 'Role',
  'facilitiesUsers.name': 'User management',
  'facilitiesUsers.title': 'Administration account',
  'facilitiesUsers.create': 'Create user',
  'facilitiesUsers.update': 'Update user',
  'facility.tabs.user': 'User management',
  'facilitiesUsers.delete.title': 'Confirm account deletion',
  'facilitiesUsers.delete.content':
    'The selected basic management accounts will be deleted and unable to recover.',
  'user.validatorUndefined': 'Full name and name',
  'user.validator-undefined': 'Please enter the username',
  'user.validatorUserName': 'Username in the wrong format',
  'users.lastAccessedAt': 'Last access',
  'user.lastAccessedAt': 'Last access',

  'users.modal.form.password': 'Password',
  'settings.router.manager': 'System installation management',
  'user.timezoneId': 'Time zone',
  'users.validator-phoneNumber': 'Please enter phone number',
  'users.validator-email': 'Please enter email',
  'users.validator-roleId': 'Please enter role',
  'users.validator-status': 'Please enter operating status',
  'users.validator-timezoneId': 'Please enter timezone',
};
