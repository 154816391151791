import dayjs from 'dayjs';

/* eslint-disable no-unused-vars */
import PermissionsEntity from '@modules/permissions/permissionsEntity';
import RolesEntity from '@modules/roles/rolesEntity';

export enum EUserStatus {
  Active = 1,
  Lock = 2,
}

class UserEntity {
  permissions: Array<PermissionsEntity> = [];

  username: string = '';

  email: string = '';

  name: string = '';

  phoneNumber?: string | number;

  dayOfBirth?: dayjs.Dayjs;

  status?: number;

  roleId: string = '';

  id: string = '';

  avatarPicture?: any;

  createdAt: string = '';

  role: Array<RolesEntity> = [];

  constructor(user?: Partial<UserEntity>) {
    if (!user) {
      return;
    }
    Object.assign(this, user);
    this.createdAt = user?.createdAt ? dayjs(user?.createdAt).format('DD/MM/YYYY') : '';
    this.dayOfBirth = user?.dayOfBirth != null ? dayjs(user?.dayOfBirth) : undefined;
  }

  static createArrayUser(arrUser: Array<Partial<UserEntity>>): Array<UserEntity> {
    if (!arrUser) {
      return [];
    }
    return arrUser.map(x => new UserEntity(x));
  }
}

export default UserEntity;
