export default {
  'gift.router-name': 'Quà tặng',
  'gift.title': 'Danh sách quà tặng',
  'gift.name': 'Tên quà tặng',
  'gift.code': 'Mã quà tặng',
  'gift.illustration': 'Hình minh họa',
  'gift.createdAt': 'Ngày tạo',
  'gift.action': 'Hành động',
  'gift.information': 'Thông tin quà tặng',
  'gift.update': 'Cập nhật quà tặng',
  'gift.create': 'Thêm quà tặng',
  'gift.title.delete': 'Xóa quà tặng',
  'gift.content.delete': 'Bạn có chắc chắn muốn xóa quà tặng này không ?',
};
