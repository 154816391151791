import React from 'react';
import { FileText } from 'react-feather';

import { EPermissions } from '@modules/permissions/permissionsEntity';
import { IRouter } from '@routers/interface';

export const routerPlayingTurns: IRouter = {
  path: '/playingTurn',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'playingTurn.router-name', //translate here for breadcrumb and sidebar
  masterLayout: true,
  menu: {
    icon: <FileText />,
  },
  permissionCode: EPermissions.PlayingTurnsView,
};

export const routerPlayingTurnsInfo: IRouter = {
  path: '/playingTurn/info/:id',
  loader: React.lazy(() => import('./component/PlayingTurnInfo')),
  exact: true,
  masterLayout: true,
  name: 'playingTurn-info.name', //translate here for breadcrumb and sidebar
  permissionCode: [EPermissions.PlayingTurnsViewDetail, EPermissions.PlayingTurnsPlayingHistory],
};
