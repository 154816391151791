export default {
  'playingHistory.title': 'Playing History',
  'playingHistory.device.name': 'Device Name',
  'playingHistory.device.specificAddress': 'Specific Installation Address',
  'playingHistory.createdAt': 'Start Time',
  'playingHistory.endTime': 'End Time',
  'playingHistory.playingHistoryGifts': 'Gift Name',
  'playingHistory.playingHistoryGifts.quantity': 'Quantity of Gifts',
  'playingHistory.winningStatus': 'Status',
  'playingHistory.winningStatus.id':
    '{status,select, 1 {Won} 2 {Not Won} 3 {Playing} other {Unknown}}',
  'playingHistory.winningStatus.id.1': 'Won',
  'playingHistory.winningStatus.id.2': 'Not Won',
  'playingHistory.winningStatus.id.3': 'Playing',
  'playingHistory.total.play': 'Total Number of Plays',
  'playingHistory.total.code': 'Total Number of Gifts Received',
};
