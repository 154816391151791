export default {
  'playingTurn.title': 'Playing Turn History',
  'playingTurn.code': 'Play Code',
  'playingTurn.remainTurn': 'Remaining Turns',
  'playingTurn.status': 'Status',
  'playingTurn.status.id': '{status, select, 1 {Not Used} 2 {Used} 3 {Scans left} other {Unknown}}',
  'playingTurn.status.id.1': 'Not Used',
  'playingTurn.status.id.2': 'Used',
  'playingTurn.status.id.3': 'Scans left',
  'playingTurn.total.code': 'Total Number of Codes',
  'playingTurn.add.code': 'Add Turn Code',
  'playingTurn.edit.code': 'Edit Turn Code',
  'playingTurn.add.quantity.code': 'Quantity of Codes',
  'playingTurn.add.quantity.play': 'Number of Plays per Code',
  'playingTurn.title.delete': 'Delete code',
  'playingTurn.content.delete': 'Do you want to delete these codes',
  'playingTurn.router-name': 'Code management',
  'playingTurn.title.list': 'Code management',
  'playingTurn.import.code': 'Download the file',
  'playingTurn-info.name': 'Code information',
  'playingTurn.scanningTime': 'Number of scans',
  'playingTurn.scanningTime.total': 'Plays per scan',
  'playingTurn.validTime': 'Valid Time',
  'playingTurn.expiredTime': 'Expired Time',
};
