export default {
  'common.overview.video': 'Overview Video',
  'common.page.lowercase': 'page',
  'common.add.image': 'Add Image',
  'common.screen.saver': 'Screen Saver',
  'common.empty': ' ',
  'common.ok': 'OK',
  'common.action': 'Action',
  'common.save': 'Save',
  'common.cancel': 'Cancel',
  'common.home': 'Home',
  'common.display': 'Display',
  'common.entries': 'entries per page',
  'common.form.required': 'Please enter',
  'common.delete': 'Delete',
  'common.import': 'Import File',
  'common.export': 'Export File',
  'common.share': 'Share',
  'common.listen': 'Listen',
  'common.statusActive': 'Active',
  'common.add': 'Add',
  'common.addSchool': 'Add School',
  'common.edit': 'Edit',
  'common.all': 'All',
  'common.keyword': 'Keyword',
  'common.statusDeActive': 'Deactivated',
  'common.button.accept': 'Accept',
  'common.button.resend.link': 'Resend Link',
  'common.button.return': 'Return',
  'link.return.login': 'Return to Login Page',
  'common.answers': 'Answer List',
  'common.valid.required': 'Please fill in',
  'common.valid.whitespace': 'Please do not enter whitespace',
  'common.valid.email': 'Must be in the correct format',
  'common.deviceType': 'Device Type',
  'common.email': 'Email Address',
  'common.update.user': 'Update Profile',
  'common.update.change-password': 'Change Password',
  'common.yes': 'Yes',
  'common.download': 'Download',
  'common.cancelNews': 'Cancel News',
  'common.logOut': 'Log Out',
  'common.profile': 'Profile',
  'common.forgotPassword': 'Forgot Password',
  'common.volumn': 'Adjust Volume',
  'common.powerOn': 'Power On',
  'common.powerOff': 'Power Off',
  'common.refresh': 'Refresh',
  'common.update': 'Update',
  'common.continue': 'Continue',
  'common.management': 'Management',
  'common.formNote': 'This field is required',
  'common.active': 'Active',
  'common.deactive': 'Deactivated',
  'common.status': 'Status',
  'common.statusNotApproval': 'Not Approved',
  'common.statusReject': 'Rejected',
  'common.invalid': 'invalid format.',
  'common.input.placeholder': 'Please enter {label}',
  'common.status.param':
    '{status,select, 0 {Not Approved} 1 {Approved} 2 {Rejected} other {Unknown}}',
  'common.status.new': 'Not Approved',
  'common.status.approval': 'Approved',
  'common.approval': 'Approval',
  'common.file-back': 'Revoke',
  'common.back': 'Back',
  'common.statusConnect': 'Connecting',
  'common.statusDisconnect': 'Disconnected',
  'common.StatusNewsletterPlaying': 'Playing Newsletter',
  'common.StatusNewsletterStop': 'Stopped Newsletter',
  'common.goBack': 'Go Back',
  'common.required.password': 'Please enter the password',
  'common.info': 'Information',
  'dashboard.area': 'Area',
  'dashboard.radio_device': 'Broadcasting Device',
  'dashboard.mtc_device': 'Public Electronic Device',
  'dashboard.audio_news': 'Broadcasting News',
  'dashboard.mtc_news': 'Public Electronic News',
  'area.placeholder': 'Please enter area name',
  'common.remove': 'Remove',
  'common.play': 'Play',
  'common.no.': 'No.',
  'common.stt': 'No.',
  'common.detail': 'Details',
  'common.non.title': 'Actions',
  'common.turnOn': 'Turn On',
  'common.turnOff': 'Turn Off',
  'common.pause': 'Pause',
  'common.convert': 'Convert',
  'common.using': 'In Use',
  'common.notUse': 'Not In Use',
  'common.check': 'Save',
  'common.close': 'Close',
  'common.noti': 'Notification',
  'common.viewPlus': 'View More',
  'common.return': 'Return',
  'common.search.placeholder': 'Enter keyword to search',
  'common.eye': 'Show',
  'common.eyeOff': 'Hide',
  'common.hidden': 'Not Displayed',
  'common.in.process': 'Uploading',
  'common.logout': 'Log Out',
  'common.logout.title': 'Confirm log out of the device',
  'common.logout.content': 'Are you sure you want to log out?',
  'common.change.password': 'Change Password',
  'common.report': 'Report',
  'common.page': 'Page',
  'common.search': 'Search',
  'common.transactions': 'Audit',
  'common.product': 'Product',
  'common.today': 'Today',
  'common.file': 'Export File',
  'common.checkAll': 'Select All',
  'common.check-in': 'Checked In',
  'common.not-check-in': 'Not Checked In',
  'common.LifeBuoy': 'Check-in Log',
  'common.total': 'Total Amount',
  'common.month': 'month',
  'common.month2': 'Month',
  'common.day': 'day',
  'common.file-edit': 'Package extension',
  'common.upload': 'Select File',
  'common.file-check-green': 'Approve',
  'common.file-check-red': 'Reject Approval',
  'common.minus': 'Delete',
  'common.preview': 'Preview',
  'common.403error': 'Error 403',
  'common.permission.notfound': 'You do not have access rights',
  'common.403note':
    'You have not been granted permission for this system, please contact admin for support.',
  'common.selected': '{rows} content selected',
  'common.confirm': 'Confirm',
  'common.volume': 'Volume',
  'common.qr': 'QR Code',
  'common.upgrade': 'Upgrade',
  'common.backToSystem': 'Back to Management System ',
  'common.vn': 'Vietnamese',
  'common.en': 'English',
  'common.copy': 'Copy',
  'common.gift': 'Adjust probability',
  'common.accept': 'Accept',
  'common.hello': 'Hello',
  'common.period': 'Period',
  'common.layout': 'Page',
  'common.default': 'Normal',
  'common.special': 'Special',
  'common.error': 'Connection Error',
  'common.clickHere': 'Click the button',
  'common.changeFile': 'to change to a new video',
  'common.changeImg': 'to change the image',
  'common.code.required': 'Code is not in the correct format',
  'common.clock': 'Adjust Play Time',
};
