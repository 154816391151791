import store from '@core/store/redux';
import UserEntity from '@modules/user/entity';

import profileStore, { setToken } from './profileStore';
import * as authenticationRepository from './repository';

const authenticationPresenter = { ...authenticationRepository };

authenticationPresenter.login = async (code, remember = false) => {
  const token: any = await authenticationRepository.login(code);
  store.dispatch(
    setToken({ token: token.accessToken, refreshToken: token.refreshToken, remember }),
  );
  return token;
};

authenticationPresenter.resetToken = payload => {
  return authenticationRepository.resetToken(payload).then((res: any) => {
    store.dispatch(profileStore.actions.resetToken(res));
    return Promise.resolve(res);
  });
};

authenticationPresenter.getProfile = () => {
  return authenticationRepository.getProfile().then((user: UserEntity) => {
    store.dispatch(
      profileStore.actions.fetchProfile({
        user,
        listPermissionCode: user?.permissions?.map(p => p.code),
      }),
    );
    return Promise.resolve(user);
  });
};

export default authenticationPresenter;
