import React from 'react';
import { Users } from 'react-feather';

import { EPermissions } from '@modules/permissions/permissionsEntity';
import { IRouter } from '@routers/interface';

export const routerCustomer: IRouter = {
  path: '/customer',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'customer.router.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
  menu: {
    icon: <Users />,
  },
  permissionCode: EPermissions.CustomersView,
};

export const routerCustomerInfo: IRouter = {
  path: '/customer/info/:id',
  loader: React.lazy(() => import('./Pages')),
  exact: true,
  name: 'customer.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
  permissionCode: EPermissions.CustomersViewDetail,
};
