export default {
  'gift.router-name': 'Gifts',
  'gift.title': 'List of Gifts',
  'gift.name': 'Gift Name',
  'gift.code': 'Gift Code',
  'gift.illustration': 'Illustration',
  'gift.createdAt': 'Created Date',
  'gift.action': 'Action',
  'gift.information': 'Gift Information',
  'gift.update': 'Update Gift',
  'gift.create': 'Add Gift',
  'gift.title.delete': 'Delete Gift',
  'gift.content.delete': 'Are you sure you want to delete this gift?',
  
};
