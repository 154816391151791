export default {
  'register.name': 'Full Name',
  'register.name.required': 'Invalid Name',
  'register.button.account': 'Register',
  'register.confirmPassword': 'Confirm Password',
  'register.phoneNumber': 'Phone Number',
  'register.province': 'Province / City',
  'register.district': 'District',
  'register.commune': 'Commune / Ward',
  'register.specificAddress': 'Specific Address',
  'register.gender': 'Gender',
  'register.avatarPicture': 'Profile Picture',
  'register.address.required': 'Invalid Address',
  'register.gender.man': 'Male',
  'register.gender.woman': 'Female',
  'register.gender.other': 'Other',
};
