export default {
  'devices.router-name': 'Device',
  'devices.title': 'Device Management',
  'devices-add.name': 'Add Device',
  'devices-form-add': 'Add Device',
  'devices-form-edit': 'Edit Device',
  'devices-edit.name': 'Edit Device',
  'devices.deviceCode': 'Device Code',
  'devices.deviceName': 'Device Name',
  'devices.province': 'Province/City',
  'devices.district': 'District',
  'devices.commune': 'Commune/Ward',
  'devices.address': 'Installation Address',
  'devices.password': 'Password',
  'devices.confirmPassword': 'Confirm Password',
  'devices.capacity': 'Capacity',
  'devices.status': 'Operation Status',
  'devices.info.title': 'Device Information',
  'devices.history.fullBarrel.title': 'Full Barrel History',
  'devices.history.collect.title': 'Collection History',
  'devices.history.fullBarrel.totalCans': 'Total Cans',
  'devices.history.fullBarrel.totalKg': 'Total Kg',
  'devices-fullBarrel.createdAt': 'Full Barrel Time',
  'devices-fullBarrel.numberOfBottles': 'Number of Bottles',
  'devices-fullBarrel.kgBottles': 'Kg of Bottles',
  'devices-fullBarrel.numberOfCans': 'Number of Cans',
  'devices-fullBarrel.kgCans': 'Kg of Cans',
  'devices-fullBarrel.totalKg': 'Total Kg',
  'devices-collect.createdAt': 'Collection Time',
  'devices-collect.numberOfBottles': 'Number of Bottles',
  'devices-collect.kgBottles': 'Kg of Bottles',
  'devices-collect.numberOfCans': 'Number of Cans',
  'devices-collect.kgCans': 'Kg of Cans',
  'devices-collect.totalKg': 'Total Kg',
  'devices-collect.collector.name': 'Collector',

  'devices.code': 'Device Code',
  'devices.name': 'Device Name',
  'devices.specificAddress': 'Installation Address',
  'devices.obtainedQuantity': 'Collected Bottles Quantity',
  'devices.validQuantity': 'Aqua Bottles Quantity',
  'devices.invalidQuantity': 'Other Brand Bottles Quantity',
  'devices.bottlesCapacity': 'Bottle Capacity',
  'devices.cansCapacity': 'Can Capacity',
  'devices.containerStatus': 'Capacity Status',
  'devices.operationStatus': 'Operation Status',
  'devices.action': 'Action',
  'devices-info.operationStatus': 'Operation Status',

  'devices.longitude': 'Longitude',
  'devices.latitude': 'Latitude',

  'devices-info.name': 'Device Information',

  'devices.capacity-empty': 'Empty Container',
  'devices.recycle-full': 'Full Recycle Bin',
  'devices.water-full': 'Full Water Bin',
  'devices.bin-two-full': 'Both Bins Full',

  'devices.status.containerStatus':
    '{status,select, 1 {Empty Container} 2 {Both Bins Full} 3 {Full Recycle Bin} 4 {Full Water Bin}  other {Undefined}}',
  'devices.status.operationStatus': '{status,select, 1 {Active} 2 {Inactive} other {Unknown}}',
  'devices.connectionStatus': 'Connection Status',
  'devices.status.connectionStatus':
    '{status,select, 1 {Connected} 2 {Disconnected} other {Unknown}}',
  'devices.status.connectionStatus.1': 'Connected',
  'devices.status.connectionStatus.2': 'Disconnected',
  'devices.history.time': 'Time',

  'devices-info.total-aqua': 'Total Aqua Bottles',
  'devices-info.gift': 'Total Bottles',
  'devices-info.brand-different': 'Total Other Brand Bottles',

  'devices-info.time': 'Time',
  'devices-info.invalidQuantity': 'Other Brand Bottles Quantity',
  'devices-info.validQuantity': 'Aqua Bottles Quantity',
  'devices-info.obtainedQuantity': 'Total Bottles',
  'devices-info.customer.nickName': 'Customer',
  'devices.content.delete': 'Are you sure you want to delete this device?',
  'devices.title.delete': 'Delete Device',
  'devices.placeholder.latitude': 'Please enter latitude',
  'devices.placeholder.longitude': 'Please enter longitude',
  'devices.info.empty': 'You do not have permission to view device information',
  'devices.probabilityWinning': 'Probability of Winning',
  'devices.modal.probabilityWinning.title': 'Adjust the winning probability of the selected device',
  'devices.location': 'Location',
  'devices.sessionDuration': 'Play Duration (seconds)',
  'devices.modal.sessionDuration.title': 'Adjust Play Time for the Selected Device',
  'devices.history.title': 'History of animal picking',
  'device-info.createdAt': 'Start Time',
  'device-info.customer.name': 'Customer Name',
  'device-info.playingHistoryGifts': 'Quantity of Gifts',
  'device-info.winningStatus': 'Status',
  'device-info.totalPlayingTurn': 'Total Number of Plays',
  'device-info.prizedGift': 'Total Number of Prized Gifts',
  'devices.content.refresh': 'Are you sure you want to restart this device?',
  'devices.history.activity': 'Activity log',
  'devices.history.gift': 'Present',
  'gift-device.title': 'Equipment Gift Management',
  'gift-device.gift.name': 'Gift name',
  'gift-device.gift.code': 'Gift code',
  'gift-device.action': 'Act',
  'gift-device.add': "Add equipment' gifts",
  'gift-device.edit': 'Equipment gift update',
  'gift-device.add.giftId': 'Present',
  'gift-device.add.quantity': 'Quantity',
  'gift-device.add.gift': "Add equipment' gifts",

  'activity-info.actionType.id':
    '{status,select, 0 {Connection Error} 1 {Not Connected} 2 {Connected} 3 {Check QR Code} 4 {Create Game Session} 5 {Send Number of Plays} 6 {Hardware Feedback} 7 {Send Winning Percentage} 8 {Send Feedback Winning Percentage} 9 {Undefined Device} 10 {Won Prize} other {Undefined}}',
  'activity-info.actionType.id.0': 'Connection Error',
  'activity-info.actionType.id.1': 'Not Connected',
  'activity-info.actionType.id.2': 'Connected',
  'activity-info.actionType.id.3': 'Check QR Code',
  'activity-info.actionType.id.4': 'Create Game Session',
  'activity-info.actionType.id.5': 'Send Number of Plays',
  'activity-info.actionType.id.6': 'Hardware Feedback',
  'activity-info.actionType.id.7': 'Send Winning Percentage',
  'activity-info.actionType.id.8': 'Send Feedback Winning Percentage',
  'activity-info.actionType.id.9': 'Undefined Device',
  'activity-info.actionType.id.10': 'Won Prize',
  'activity-info.time': 'Time',
  'activity-info.actionType': 'Status',
  'showData.title': 'Device data',
};
