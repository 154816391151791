export default {
  'customer.router.name': 'Customer',
  'customer.title': 'Customer Management',
  'customer.update': 'Update Customer',
  'customer.name': 'Full Name',
  'customer.nickName': 'Username',
  'customer.customerName': 'Customer Name',
  'customer.phoneNumber': 'Phone Number',
  'customer.fullName': 'Full Name',
  'customer.address': 'Address',
  'customer.province': 'Province/City',
  'customer.district': 'District',
  'customer.ward': 'Commune/Ward',
  'customer.status': 'Operation Status',
  'customer.info': 'Customer Information',
  'customer.emailAddress': 'Email',

  'ItemWarehouse.tab.name': 'Item Warehouse',
  'VouchersWarehouse.tab.name': 'Voucher Warehouse',
  'GiftWarehouse.tab.name': 'Gift Warehouse',
  'ScoreHistory.tab.name': 'Score History',
  'RankHistory.tab.name': 'Rank History',

  'customer.validate-phone': 'Invalid phone number',

  'customer.greenCoin': 'Green Coin </br> (Remaining/Total Accumulated)',
  'customer.lastWeekRanking': 'Last Week Ranking',
  'customer.specificAddress': 'Specific Address',
  'customer.currentWeekRanking': 'Current Week Ranking',
  'customer.overallRanking': 'Overall Ranking',
  'customer.action': 'Action',
  'customer.action.active': 'Activate',
  'customer.action.lock': 'Deactivate',
  'customer.confirm.content.active': 'Do you want to activate the user?',
  'customer.confirm.content.lock': 'Do you want to deactivate the user?',
  'customer.status.param': '{status,select, 1 {Active} 2 {Inactive} other {Unknown}}',
  'customer.accumulatedCoins': 'Accumulated Coins',

  'scoreHistory.device.specificAddress': 'Installation Address',
  'scoreHistory.device.name': 'Device',
  'scoreHistory.time': 'Time',
  'scoreHistory.invalidQuantity': 'Quantity of Other Brand Bottles',
  'scoreHistory.validQuantity': 'Quantity of Aqua Bottles',
  'scoreHistory.score': 'Score Achieved',
  'scoreHistory.card.score': 'Total Score Achieved',
  'scoreHistory.card.invalidQuantity': 'Total Quantity of Other Brand Bottles',
  'scoreHistory.card.validQuantity': 'Total Quantity of Aqua Bottles',

  'giftWarehouse.status.type': '{status,select, 1 {Physical Item} 2 {Voucher} other {Unknown}}',
  'giftWarehouse.status.source':
    '{status,select, 1 {Admin Gift} 2 {Weekly Ranking} other {Unknown}}',
  'giftWarehouse.total-gift': 'Total Gifts',
  'giftWarehouse.rank-gift': 'Ranking Gifts',
  'giftWarehouse.different-gift': 'Other Gifts',

  'giftWarehouse.awardName': 'Award Type',
  'giftWarehouse.rank': 'Ranking',
  'giftWarehouse.code': 'Gift Code',
  'giftWarehouse.name': 'Gift Name',
  'giftWarehouse.type': 'Gift Type',
  'giftWarehouse.coin': 'Green Coin Amount',
  'giftWarehouse.sourceGift': 'Gift Source',
  'giftWarehouse.createdAt': 'Time',
  'giftWarehouse.giftRevokingStatus': 'Gift Status',
  'giftWarehouse.status.giftRevokingStatus':
    '{status,select, 1 {Revoked} 2 {Issued} other {Unknown}}',
  'giftWarehouse.action': 'Action',
  'giftWarehouse.type-artifact': 'Physical Item',
  'giftWarehouse.type-voucher': 'Voucher',
  'giftWarehouse.source-awardSource': 'Admin Gift',
  'giftWarehouse.source-rankSource': 'Weekly Ranking',
  'giftWarehouse.source-Item-offers': 'Item Offers',
  'giftWarehouse.information': 'Gift Information',

  'itemWarehouse.action': 'Action',
  'itemWarehouse.item.thumbnail': 'Image',
  'itemWarehouse.item.code': 'Item Code',
  'itemWarehouse.item.name': 'Item Name',
  'itemWarehouse.item.group.name': 'Item Group',
  'itemWarehouse.item.quantity': 'Green Coin',
  'itemWarehouse.item.collection.name': 'Collection',
  'itemWarehouse.item.type': 'Item Type',
  'itemWarehouse.equippingStatus': 'Status',
  'itemWarehouse.createdAt': 'Purchase Time',
  'itemWarehouse.status.type': '{status,select, 1 {Normal Type} 2 {Mystery Type} other {Unknown}}',
  'itemWarehouse.status.equippingStatus':
    '{status,select, 1 {Equipped} 2 {Not Equipped} 3 {Revoked} other {Unknown}}',
  'itemWarehouse.status.normal': 'Normal Type',
  'itemWarehouse.status.mystery': 'Mystery Type',
  'itemWarehouse.status.equipping': 'Equipped',
  'itemWarehouse.status.notEquipping': 'Not Equipped',
  'itemWarehouse.status.revoked': 'Revoked',
  'itemWarehouse.card.itemQuantity': 'Total Items',
  'itemWarehouse.card.coinQuantity': 'Total Green Coin Spent on Items',
  'itemWarehouse.information': 'Item Information',
  'customer.title.recall': 'Recall Item',
  'customer.content.recall': 'Are you sure you want to recall this item?',
  'customer.title.gift-recall': 'Recall Gift',
  'customer.content.gift-recall': 'Are you sure you want to recall this gift?',

  'vouchersWarehouse.price': 'Redemption Points (Green Coin)',
  'vouchersWarehouse.name': 'Voucher Type',
  'vouchersWarehouse.receivingTime': 'Redemption Time',
  'vouchersWarehouse.usageStatus': 'Usage Status',
  'vouchersWarehouse.totalVoucher': 'Total Vouchers',
  'vouchersWarehouse.totalGreencoin': 'Total Green Coin Redemption',

  'vouchersWarehouse.used': 'Used',
  'vouchersWarehouse.unUsed': 'Unused',
  'vouchersWarehouse.normal': 'Normal Type',
  'vouchersWarehouse.prioritize': 'Priority Type',
  'vouchersWarehouse.information': 'Voucher Warehouse Information',
  'Green.Coin': 'Green Coin',
  'vouchersWarehouse.amountCoin': 'Amount Received (VND)',
  'vouchersWarehouse.phoneNumber': 'Receiving Phone Number',

  'rankHistory.numberWeeksRanked': 'Total Weeks Ranked',
  'rankHistory.awardWinningRatings': 'Number of Prize-Winning Rankings',
  'rankHistory.title.closingTime': 'Time',
  'rankHistory.rank': ' Ranking',
  'rankHistory.awardName': 'Award Type',
  'rankHistory.coin': 'Green Coin Ranking Amount',
  'rankHistory.week.id': 'Number of Weeks',
  'rankHistory.closingTime': 'Prize Closing Time',
};
