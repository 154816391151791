/* eslint-disable no-unused-vars */
class PermissionsEntity {
  code: string = '';

  name: string = '';

  resource: string = '';

  constructor(permissions: Partial<PermissionsEntity>) {
    if (!permissions) {
      return;
    }
    Object.assign(this, permissions);
  }

  static createListPermissions(listPermissions: Array<Partial<PermissionsEntity>>) {
    if (!Array.isArray(listPermissions)) {
      return [];
    }
    return listPermissions.map((permissions: Partial<PermissionsEntity>) => {
      return new PermissionsEntity(permissions);
    });
  }
}
export default PermissionsEntity;

export enum EPermissions {
  CustomersPlayingHistory = 'CustomersPlayingHistory',
  CustomersPlayingTurnsView = 'CustomersPlayingTurnsView',
  CustomersPlayingTurnsCreate = 'CustomersPlayingTurnsCreate',
  CustomersUpdate = 'CustomersUpdate',
  CustomersView = 'CustomersView',
  CustomersViewDetail = 'CustomersViewDetail',
  DevicesActivityLog = 'DevicesActivityLog',
  DevicesCreate = 'DevicesCreate',
  DevicesDelete = 'DevicesDelete',
  DevicesPlayingHistory = 'DevicesPlayingHistory',
  DevicesUpdate = 'DevicesUpdate',
  DevicesRestart = 'DevicesRestart',
  DevicesView = 'DevicesView',
  DevicesViewDetail = 'DevicesViewDetail',
  DevicesDeviceGiftsView = 'DevicesDeviceGiftsView',
  DevicesDeviceGiftsCreate = 'DevicesDeviceGiftsCreate',
  DevicesDeviceGiftsUpdate = 'DevicesDeviceGiftsUpdate',
  GiftsCreate = 'GiftsCreate',
  GiftsDelete = 'GiftsDelete',
  GiftsUpdate = 'GiftsUpdate',
  GiftsView = 'GiftsView',
  GiftsViewDetail = 'GiftsViewDetail',
  PlayingTurnsCreate = 'PlayingTurnsCreate',
  PlayingTurnsDelete = 'PlayingTurnsDelete',
  PlayingTurnsImport = 'PlayingTurnsImport',
  PlayingTurnsPlayingHistory = 'PlayingTurnsPlayingHistory',
  PlayingTurnsUpdate = 'PlayingTurnsUpdate',
  PlayingTurnsView = 'PlayingTurnsView',
  PlayingTurnsViewDetail = 'PlayingTurnsViewDetail',
  RolesCreate = 'RolesCreate',
  RolesDelete = 'RolesDelete',
  RolesUpdate = 'RolesUpdate',
  RolesView = 'RolesView',
  RolesViewDetail = 'RolesViewDetail',
  UsersCreate = 'UsersCreate',
  UsersDelete = 'UsersDelete',
  UsersUpdate = 'UsersUpdate',
  UsersView = 'UsersView',
  UsersViewDetail = 'UsersViewDetail',
}
