export default {
  'reset.password.title': 'Reset Password',
  'reset.password.newPassword': 'A new password',
  'reset.password.confirm.newPassword': 'Confirm new password',
  'reset.password.title.error': 'Connection errors',
  'reset.password.text.error':
    'There was an error connecting or your link has expired. Please try again to reset your password.',
  'reset.password.notification':
    'There was an error during the connection or your link expired.Please try again or ask to resend the link to reset your password.',
  'reset.password.not.match': 'Two passwords you entered do not match!',
};
