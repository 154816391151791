import 'dayjs/locale/en';

import enUS from 'antd/lib/locale/en_US';

import auth from './auth';
import common from './common';
import customer from './customer';
import devices from './devices';
import gift from './gift';
import pageError from './pageError';
import playingHistory from './playingHistory';
import playingTurn from './playingTurn';
import roles from './roles';
import server from './server';
import user from './user';

export default {
  ...enUS,
  ...common,
  ...server,
  ...auth,
  ...pageError,
  ...devices,
  ...roles,
  ...customer,
  ...playingHistory,
  ...playingTurn,
  ...gift,
  ...user,
};
