export const logo = require('./logo.png');
export const logoAuth = require('./logo_auth.png');
export const imgAvatar = require('./avatar.jpg');
export const languageVN = require('./vn.png');
export const languageEN = require('./en.png');
export const Img404 = require('./error.svg');
export const iconTotal = require('./iconTotal.png');
export const iconDevice = require('./iconDevice.png');
export const iconHeineken = require('./iconHeineken.png');
export const iconOther = require('./iconOther.png');
export const iconDot = require('./dotIcon.png');
export const mapPin = require('./mapPin.svg');
export const img404 = require('./bg_404.png');
export const locationBigIcon = require('./dashboard_location_big_icon.png');
export const locationSmallIcon = require('./dashboard_location_small_icon.png');
export const deviceBigIcon = require('./dashboard_device_big_icon.png');
export const deviceSmallIcon = require('./dashboard_device_small_icon.png');
export const newsBigIcon = require('./dashboard_news_big_icon.png');
export const newsSmallIcon = require('./dashboard_news_small_icon.png');
export const capacityBigIcon = require('./dashboard_capacity_big_icon.png');
export { default as ArrowLeftImg } from './arrow-left.png';
export { default as LogoutIcon } from './LogoutIcon.png';
export { default as audioThumbnail } from './audio-thumbnail.png';
export { default as defaultMedia } from './audio-thumbnail.png';
export { default as iconNewsDetailTable } from './news_detail_table_icon_img.png';
export { default as modalRefuseIcon } from './modal_refuse_icon_img.png';
export { default as deviceRed } from './dashboard_icon_red.png';
export { default as deviceGreen } from './dashboard_icon_green.png';
export { default as uploadIconImg } from './upload_icon_img.png';
export { default as ForwardsIconImg } from './ForwardsIcon.png';
export { default as BackWardsIconImg } from './BackWardsIcon.png';
export { default as fileError } from './file-error.png';

export { default as DownIconImg } from './DownIconImg.png';
export { default as ImgBackHome } from './img-home.png';
export { default as ImgLogout } from './img-logout.png';
