export default {
  'profile.edit': 'Edit Profile',
  'profile.update': 'Save Changes',
  'profile.fullName': 'Full Name',
  'profile.userName': 'Username',
  'profile.phone': 'Phone Number',
  'profile.roles': 'Roles',
  'profile._password': 'Password',
  'profile.createDateTime': 'Creation Date',
  'profile.isActive': 'Active Status',
  'profile.change.password.title': 'Reset Password',
  'profile.change.password.btn': 'Change Password',
  'profile.change.password.subtitle': 'Please choose a new password to log in',
  'profile.newPassword': 'Enter New Password',
  'profile.confirm.newPassword': 'Confirm New Password',
  'password.not.match': 'Passwords do not match',
  'profile.name': 'Profile',
  'profile.oldPassword': 'Enter Old Password',
  'profile.title': 'Personal Information',
  'profile.tariffPackagesMonth': 'Monthly Tariff Packages',
  'profile.tariffPackagesTime': 'Expiration Time:',
  'profile.tariffPackagesAuto': 'Auto Renewal:',
  'profile.tariffPackages-ghn': 'Renew Now:',
  'profile.tariffPackages-btn-ghn': 'Renew ',
  'profile.tariffPackagesUpdate': 'Upgrade Tariff Packages:',
  'profile.tariffPackagesUpdate-btn': 'Upgrade',
  'profile.tariffPackages-history': 'Renewal History',
  'profile.tariffPackages-info': 'Subscription Card Information',
  'profile.tariffPackages.title': 'Tariff Packages Information',
  'profile.code.title': 'Account Deactivated',
  'profile.code.content':
    'Your account is currently deactivated and cannot access the system. Please contact the system administrator for resolution.',

  'message.required.oldPassword': 'Please enter the old password',
  'message.pattern.oldPassword': 'Old password format is incorrect',
  'message.required.password': 'Please enter the new password',
  'message.pattern.password': 'New password format is incorrect',
  'message.required.confirmPassword': 'Please confirm the new password',
};
