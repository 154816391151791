import React from 'react';

import { LaptopOutlined } from '@ant-design/icons';
import { EPermissions } from '@modules/permissions/permissionsEntity';
import { IRouter } from '@routers/interface';

export const routerDevices: IRouter = {
  path: '/devices',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'devices.router-name', //translate here for breadcrumb and sidebar
  masterLayout: true,
  menu: {
    icon: <LaptopOutlined />,
  },
  permissionCode: EPermissions.DevicesView,
};

export const routerFormDeviceAdd: IRouter = {
  path: '/devices/add',
  loader: React.lazy(() => import('./components/FormDevice')),
  exact: true,
  name: 'devices-add.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
  permissionCode: EPermissions.DevicesCreate,
};

export const routerFormDeviceEdit: IRouter = {
  path: '/devices/:id',
  loader: React.lazy(() => import('./components/FormDevice')),
  exact: true,
  name: 'devices-edit.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
  permissionCode: EPermissions.DevicesUpdate,
};

export const routerDevicesInfo: IRouter = {
  path: '/devices/info/:id',
  loader: React.lazy(() => import('./components/DeviceInfo')),
  exact: true,
  masterLayout: true,
  name: 'devices-info.name', //translate here for breadcrumb and sidebar
  permissionCode: EPermissions.DevicesViewDetail,
};
