import { routerForgotPassword } from '@view/Auth/ForgotPassword/router';
import { routerLogin } from '@view/Auth/Login/router';
import { routerViewProfile } from '@view/Auth/Profiles/router';
import { routerResetPassword } from '@view/Auth/ResetPassword/router';
import { routerCustomer, routerCustomerInfo } from '@view/Customer/router';
import {
  routerDevices,
  routerDevicesInfo,
  routerFormDeviceAdd,
  routerFormDeviceEdit,
} from '@view/Devices/router';
import { routerGift } from '@view/Gift/router';
import { routerHome } from '@view/Home/router';
import { routerPageError } from '@view/PageError/router';
import { routerPlayingTurns, routerPlayingTurnsInfo } from '@view/PlayingTurns/router';
import { routerRoles, routerSetting, routerUser } from '@view/Setting/router';

import { IRouter } from './interface';

export const privatePage: IRouter[] = [
  routerHome,
  routerViewProfile,
  routerCustomer,
  routerCustomerInfo,
  routerDevices,
  routerDevicesInfo,
  routerGift,
  routerFormDeviceAdd,
  routerFormDeviceEdit,
  routerPlayingTurns,
  routerPlayingTurnsInfo,
  routerSetting,
  routerUser,
  routerRoles,
  routerPageError,
];

export const publicPage: IRouter[] = [
  routerForgotPassword,
  routerResetPassword,
  routerLogin,
  routerPageError,
];
