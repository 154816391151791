/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import { Selector, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { logo } from '@assets/images';
import CONFIG from '@config';
import store from '@core/store/redux';
import { RootState } from '@modules';
import UserEntity from '@modules/user/entity';
import { privatePage } from '@routers/mainRouter';
import { useAltaIntl } from '@shared/hook/useTranslate';

import MenuCustom from './ItemMenu';

interface IHeaderComponent {
  profile?: UserEntity;
}

const HeaderComponentSelector: Selector<RootState, IHeaderComponent> = (state: RootState) => {
  return {
    profile: state.profile.user,
  };
};

const SidebarComponent: React.FC<{
  className: string;
  setClassName: (className: string) => void;
}> = props => {
  const { formatMessage } = useAltaIntl();
  const location = useLocation();
  const navigate = useNavigate();
  const refMouseEnter = useRef<any>();
  const { profile } = useSelector(HeaderComponentSelector);
  const { className, setClassName } = props;
  const [width, setWidth] = useState<string | number>();
  const [collapse, setCollapse] = useState<boolean>(false);
  const onClick = (e: any) => {
    setClassName('sider-component big');
    e.preventDefault();
    e.stopPropagation();
    setCollapse(false);
  };

  const onMouseEnter = e => {
    if (refMouseEnter.current) {
      clearTimeout(refMouseEnter.current);
    }
    refMouseEnter.current = setTimeout(() => {
      onClick(e);
    }, 800);
  };

  const onMouseLeave = () => {
    if (refMouseEnter.current) {
      clearTimeout(refMouseEnter.current);
    }
  };

  useEffect(() => {
    if (className === 'sider-component') {
      setWidth('30rem');
      setCollapse(true);
    } else {
      setWidth('100%');
    }
  }, [className]);

  return (
    <div
      className={className}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div className="mask" style={{ width }}>
        <div className="wrapper-top">
          <div className="wrapper-logo">
            <div className={`logo ${collapse ? 'logo-small' : ''}`}>
              <img src={logo} alt="logo" onClick={() => navigate('/')} />
            </div>
          </div>
          <div className="wrapper-sidebar">
            <MenuCustom listNav={privatePage} location={location.pathname} collapse={collapse} />
          </div>
        </div>
        {/* <div className="wrapper-footer">
          <div className="footer__container">
            <div className="item__left">
              <div
                className="dropdown__profile__img"
                onClick={() => {
                  return navigate('/profile');
                }}
              >
                <img
                  alt="img-avatar"
                  className="img-avatar"
                  src={profile?.avatarPicture || imgAvatar}
                />
              </div>

              <div className="text-avatar">
                <Typography.Paragraph
                  ellipsis={{ rows: 1, tooltip: true }}
                  className="name-avatar text"
                >
                  {profile?.name || '--'}
                </Typography.Paragraph>
                <Typography.Paragraph
                  ellipsis={{ rows: 1, tooltip: true }}
                  className="role-avatar text"
                >
                  {profile?.role?.name || '--'}
                </Typography.Paragraph>
              </div>
            </div>

            <div className="item__right">
              <img
                src={LogoutIcon}
                alt=""
                className="icon-logout"
                onClick={() => {
                  ConfirmReStart({
                    modifiers: 'orange',
                    icon: <Icon.LogOut className="text-[#fff]" />,
                    okText: formatMessage('common.yes'),
                    title: formatMessage('common.logout.title'),
                    handleOk: () => {
                      store.dispatch(removeProfile());
                      //@ts-ignore
                      window.location.href = CONFIG.LOGIN_PAGE;
                    },
                    handleCancel: () => {},
                  });
                }}
              />
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default React.memo(SidebarComponent);
