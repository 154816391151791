export default {
  'register.name': 'Họ và tên',
  'register.name.required': 'Họ và tên không hợp lệ',
  'register.button.account': 'Đăng ký',
  'register.confirmPassword': 'Xác nhận mật khẩu',
  'register.phoneNumber': 'Số điện thoại',
  'register.province': 'Tỉnh / Thành phố',
  'register.district': 'Quận / huyện',
  'register.commune': 'Phường / Xã',
  'register.specificAddress': 'Địa chỉ cụ thể',
  'register.gender': 'Giới tính',
  'register.avatarPicture': 'Ảnh đại diện',
  'register.address.required': 'Địa chỉ không hợp lệ',
  'register.gender.man': 'Nam',
  'register.gender.woman': 'Nữ',
  'register.gender.other': 'Khác',
};
