import React from 'react';
import { Gift } from 'react-feather';

import { EPermissions } from '@modules/permissions/permissionsEntity';
import { IRouter } from '@routers/interface';

export const routerGift: IRouter = {
  path: '/gift',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'gift.router-name', //translate here for breadcrumb and sidebar
  masterLayout: true,
  menu: {
    icon: <Gift />,
  },
  permissionCode: EPermissions.GiftsView,
};
