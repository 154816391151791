export default {
  'devices.router-name': 'Thiết bị',
  'devices.title': 'Quản lý thiết bị',
  'devices-add.name': 'Thêm thiết bị',
  'devices-form-add': 'Thêm thiết bị',
  'devices-form-edit': 'Cập nhật thiết bị',
  'devices-edit.name': 'Cập nhật thiết bị',
  'devices.deviceCode': 'Mã thiết bị',
  'devices.deviceName': 'Tên thiết bị',
  'devices.province': 'Tỉnh/Thành phố',
  'devices.district': 'Quận/Huyện',
  'devices.commune': 'Phường/Xã',
  'devices.address': 'Địa chỉ lắp đặt',
  'devices.password': 'Mật khẩu',
  'devices.confirmPassword': 'Xác nhận mật khẩu',
  'devices.capacity': 'Sức chứa',
  'devices.status': 'Trạng thái hoạt động',
  'devices.info.title': 'Thông tin thiết bị',
  'devices.history.fullBarrel.title': 'Lịch sử đầy thùng',
  'devices.history.collect.title': 'Lịch sử thu gom thùng chứa',
  'devices.history.fullBarrel.totalCans': 'Tổng số lon',
  'devices.history.fullBarrel.totalKg': 'Tổng số kg',
  'devices-fullBarrel.createdAt': 'Thời gian đầy thùng',
  'devices-fullBarrel.numberOfBottles': 'Số lượng chai',
  'devices-fullBarrel.kgBottles': 'Số Kg chai',
  'devices-fullBarrel.numberOfCans': 'Số lượng lon',
  'devices-fullBarrel.kgCans': 'Số Kg lon',
  'devices-fullBarrel.totalKg': 'Tổng số Kg',
  'devices-collect.createdAt': 'Thời gian thu gom',
  'devices-collect.numberOfBottles': 'Số chai',
  'devices-collect.kgBottles': 'Số Kg chai',
  'devices-collect.numberOfCans': 'Số lon',
  'devices-collect.kgCans': 'Số Kg lon',
  'devices-collect.totalKg': 'Tổng số kg',
  'devices-collect.collector.name': 'Người thu gom',

  'devices.code': 'Mã thiết bị',
  'devices.name': 'Tên thiết bị',
  'devices.specificAddress': 'Địa chỉ lắp đặt',
  'devices.obtainedQuantity': 'Số lượng chai đã thu',
  'devices.validQuantity': 'Số chai của Aqua',
  'devices.invalidQuantity': 'Số chai của brand khác',
  'devices.bottlesCapacity': 'Thùng chai',
  'devices.cansCapacity': 'Thùng lon',
  'devices.containerStatus': 'Sức chứa',
  'devices.operationStatus': 'Trạng thái hoạt động',
  'devices.action': 'Hành động',
  'devices-info.operationStatus': 'Trạng thái',

  'devices.longitude': 'Kinh độ',
  'devices.latitude': 'Vĩ độ',

  'devices-info.name': 'Thông tin thiết bị',

  'devices.capacity-empty': 'Thùng chứa rỗng',
  'devices.recycle-full': 'Thùng rác đầy',
  'devices.water-full': 'Thùng nước đầy',
  'devices.bin-two-full': 'Cả hai thùng đầy',

  'devices.status.containerStatus':
    '{status,select, 1 {Thùng chứa rỗng} 2 {Cả hai thùng đầy} 3 {Thùng rác đầy} 4 {Thùng nước đầy}  other {Không xác định}}',

  'devices.status.operationStatus':
    '{status,select, 1 {Đang hoạt động} 2 {Ngưng hoạt động} other {Không xác định}}',

  'devices.connectionStatus': 'Trạng thái kết nối',
  'devices.status.connectionStatus':
    '{status,select, 1 {Đã kết nối} 2 {Mất kết nối} other {Không xác định}}',
  'devices.status.connectionStatus.1': 'Đã kết nối',
  'devices.status.connectionStatus.2': 'Mất kết nối',

  'devices.history.time': 'Thời gian',
  'devices-info.total-aqua': 'Tổng số chai Aqua',
  'devices-info.gift': 'Tổng số chai',
  'devices-info.brand-different': 'Tổng số chai brand khác',

  'devices-info.time': 'Thời gian',
  'devices-info.invalidQuantity': 'Số lượng chai của brand khác',
  'devices-info.validQuantity': 'Số lượng chai là Aqua',
  'devices-info.obtainedQuantity': 'Tổng số chai',
  'devices-info.customer.nickName': 'Khách hàng',
  'devices.content.delete': 'Bạn có chắc chắn muốn xóa thiết bị này không ?',
  'devices.title.delete': 'Xóa thiết bị',
  'devices.placeholder.latitude': 'Vui lòng nhập vĩ độ',
  'devices.placeholder.longitude': 'Vui lòng nhập kinh độ',
  'devices.info.empty': 'Bạn không có quyền xem thông tin thiết bị',
  'devices.probabilityWinning': 'Xác suất trúng thưởng',
  'devices.sessionDuration': 'Thời gian chơi (giây)',
  'devices.modal.probabilityWinning.title':
    'Điều chỉnh xác suất trúng thưởng của thiết bị được chọn',
  'devices.modal.sessionDuration.title': 'Điều chỉnh thời gian chơi của thiết bị được chọn',
  'devices.location': 'Vị trí',
  'devices.history.title': 'Lịch sử gắp thú',

  'device-info.createdAt': 'Thời gian bắt đầu',
  'device-info.customer.name': 'Tên khách hàng',
  'device-info.playingHistoryGifts': 'Số lượng quà tặng',
  'device-info.winningStatus': 'Trạng thái',
  'device-info.totalPlayingTurn': 'Tổng số lượt chơi',
  'device-info.prizedGift': 'Tổng số quà nhận được',
  'devices.content.refresh': 'Bạn có chắc chắn muốn khởi động lại thiết bị này không ?',
  'devices.history.activity': 'Nhật ký hoạt động',
  'devices.history.gift': 'Quà tặng',
  'gift-device.title': 'Quản lý quà tặng thiết bị',
  'gift-device.gift.name': 'Tên quà tặng',
  'gift-device.gift.code': 'Mã quà tặng',
  'gift-device.action': 'Hành động',
  'gift-device.add': 'Thêm quà thiết bị',
  'gift-device.edit': 'Cập nhật quà thiết bị',
  'gift-device.add.giftId': 'Quà tặng',
  'gift-device.add.quantity': 'Số lượng',
  'gift-device.add.gift': 'Thêm quà thiết bị',
  'activity-info.actionType.id':
    '{status,select, 0 {Kết nối lỗi} 1 {Không kết nối} 2 {Đã kết nối} 3 {Kiểm tra QR} 4 {Tạo phiên chơi} 5 {Gửi số lượt chơi} 6 {Phần cứng đang phản hồi} 7 {Gửi phần trăm trúng thưởng} 8 {Gửi phản hồi phần trăm trúng thưởng} 9 {Thiết bị không xác định} 10 {Đã trúng quà} other {Không xác định}}',
  'activity-info.actionType.id.0': 'Kết nối lỗi',
  'activity-info.actionType.id.1': 'Không kết nối',
  'activity-info.actionType.id.2': 'Đã kết nối',
  'activity-info.actionType.id.3': 'Kiểm tra QR',
  'activity-info.actionType.id.4': 'Tạo phiên chơi',
  'activity-info.actionType.id.5': 'Gửi số lượt chơi',
  'activity-info.actionType.id.6': 'Phần cứng đang phản hồi',
  'activity-info.actionType.id.7': 'Gửi phần trăm trúng thưởng',
  'activity-info.actionType.id.8': 'Gửi phản hồi phần trăm trúng thưởng',
  'activity-info.actionType.id.9': 'Thiết bị không xác định',
  'activity-info.actionType.id.10': 'Đã trúng quà',
  'activity-info.time': 'Thời gian',
  'activity-info.actionType': 'Trạng thái',
  'showData.title': 'Dữ liệu thiết bị',
};
