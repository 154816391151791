export default {
  'playingHistory.title': 'Lịch sử lượt chơi',
  'playingHistory.device.name': 'Tên thiết bị',
  'playingHistory.device.specificAddress': 'Địa chỉ lắp đặt',
  'playingHistory.createdAt': 'Thời gian bắt đầu',
  'playingHistory.endTime': 'Thời gian kết thúc',
  'playingHistory.playingHistoryGifts': 'Tên quà tặng',
  'playingHistory.playingHistoryGifts.quantity': 'Số lượng quà tặng',
  'playingHistory.winningStatus': 'Trạng thái',
  'playingHistory.winningStatus.id':
    '{status,select, 1 {Đã trúng} 2 {Không trúng} 3 {Đang chơi} other {Không xác định}}',
  'playingHistory.winningStatus.id.1': 'Đã trúng',
  'playingHistory.winningStatus.id.2': 'Không trúng',
  'playingHistory.winningStatus.id.3': 'Đang chơi',
  'playingHistory.total.play': 'Tổng số lượt chơi',
  'playingHistory.total.code': 'Tổng số quà nhận được',
};
